import CookieConfig from "@/components/Templates/Main/CookieConfig";
import AnalyticsConfig, {
  GTManagerAditional,
} from "@/components/Templates/Main/AnalyticsConfig";
import {
  GTManager,
  MSClarity,
  MetaPixel,
} from "@/components/Templates/Main/AnalyticsConfig";
import Head from "next/head";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { renderMetaTags } from "react-datocms";
import { useTranslation } from "next-i18next";
import Link from "next/link";

export default function MainLayout({ children }) {
  const { route, query } = useRouter();
  const skipCookie = query.skipCookie === "true";
  const currentLocale = useRouter(`locales`);
  const header = children.props?.siteConfig?.headerMenu;
  const footer = children.props?.siteConfig?.footerMenu;
  const locales = children.props?.siteConfig?._allMetadataLocales;
  const pageMetaTags =
    children.props?.data?.brandsPortfolio?.seo ||
    children.props?.data?.seo ||
    children.props?.data?.product?._seoMetaTags ||
    children.props?.data?.brandsProduct?.seo;
  const siteMetaTags = children.props?.siteConfig?._seoMetaTags;
  const cookieID = children.props?.siteConfig?.cookieBotId;
  const googleID = children.props?.siteConfig?.googleAnalyticsId;
  const clarityID = children.props?.siteConfig?.microsoftClarityId;
  const consoleID = children.props?.siteConfig?.googleConsoleId;
  const metaID = children.props?.siteConfig?.metaPixelId;
  const gtmID = children.props?.siteConfig?.gtmId;
  const siteTitle = children.props?.siteConfig?.metadata;
  const pageTitle =
    children.props?.data?.brandsProduct?.metadata ||
    children.props?.data?.metadata ||
    children.props?.data?.product?.productName ||
    children.props?.data?.awards?.allAwards[0]?.product?.productName ||
    children.props?.data?.ratings?.allRatings[0]?.product?.productName;

  const Footer = dynamic(() => import("@/components/Templates/Main/Footer"));
  const GenericHeader = dynamic(() =>
    import("@/components/Templates/Main/Header")
  );

  const anchorExecuteScroll = () => {
    // e.preventDefault();
    document.getElementById("mainContent").scrollIntoView();
  };

  const { t } = useTranslation();
  const renderCookieConfig = () => {
    return (
      <CookieConfig
        id={cookieID}
        key={`locale-${currentLocale.locale}`}
        currentLocale={currentLocale.locale}
      />
    );
  };

  return (
    <>
      <Link href={`#mainContent`}>
        <a className="sr-only hidden" onClick={anchorExecuteScroll}>
          {t("skipToMainContent")}
        </a>
      </Link>
      <Head>
        {!skipCookie && (
          <link rel="preconnect" href="https://consentcdn.cookiebot.com/" />
        )}
        {consoleID?.length > 0 && (
          <meta name="google-site-verification" content={consoleID} />
        )}

        <link
          rel="icon"
          type="image/png"
          href={children.props?.siteConfig?.favicon?.url}
        />
        {(pageMetaTags &&
          pageMetaTags?.map((meta, key) => {
            if (meta.tag === "meta" && meta.attributes !== null) {
              return (
                <meta
                  key={key}
                  name={meta.attributes.property}
                  property={meta.attributes.property}
                  content={meta.attributes.content}
                />
              );
            } else if (meta.tag === "title") {
              return (
                <title key={key}>
                  {meta.content} - {siteTitle}
                </title>
              );
            }
          })) ||
          siteMetaTags?.map((meta, key) => {
            if (meta.tag === "meta" && meta.attributes !== null) {
              return (
                <meta
                  key={key}
                  name={meta.attributes.name}
                  property={meta.attributes.property}
                  content={meta.attributes.content}
                />
              );
            } else if (meta.tag === "title") {
              return <title key={key}>{siteTitle}</title>;
            }
          })}

        {renderMetaTags([...(pageMetaTags || siteMetaTags)])}
      </Head>

      {route != "/age-gate" ? (
        <GenericHeader
          currentLocale={currentLocale.locale}
          locales={locales}
          data={header}
          social={footer}
        >
          {children}
        </GenericHeader>
      ) : null}
      {route == "/" ? (
        <h1 className="sr-only">{siteTitle?.title}</h1>
      ) : (
        <h1 className="sr-only">{pageTitle?.title || pageTitle}</h1>
      )}
      <main
        className={`${route == "/age-gate" && "age-gate"}`}
        id="mainContent"
      >
        {children}
      </main>
      <Footer
        route={route}
        tokenColor={[
          children.props?.siteConfig?.primary,
          children.props?.siteConfig?.dark,
        ]}
        data={footer}
      >
        {children}
      </Footer>
      <GTManagerAditional id={gtmID} />
      <AnalyticsConfig id={googleID} />
      <GTManager id={gtmID} />
      <MSClarity id={clarityID} />
      <MetaPixel id={metaID} />
      {!skipCookie && route != "/age-gate" ? renderCookieConfig() : null}
    </>
  );
}
